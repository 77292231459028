<template>
  <b-modal
    ref="modalAddKeywork"
    modal-class="modal-primary"
    title-class="h3 text-white"
    :title="`ADD KEYWORD FOR ${item.name}`"
    size="md"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors }"
        name="section-user"
        rules="required"
      >
        <b-form-group label="Insert Keywords" label-for="input-1">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <template #prepend>
              <div class="input-group-text">
                <b-icon icon="box-seam" class="h4 m-0"> </b-icon>
              </div>
            </template>
            <b-form-tags
              input-id="tags-basic"
              separator=" ,;"
              v-model="keyword"
              :state="errors.length > 0 ? false : null"
              placeholder="Add Keyword by space, comma, or semicolon"
              remove-on-delete
            />
            <template #append>
              <b-button variant="primary" @click="saveKeywords()">ADD</b-button>
            </template>
          </b-input-group>
        </b-form-group>
        <div class="container-keywords">
          <b-table striped hover small :fields="fields" :items="data">
            <template #cell(keyword)="data">
              <div class="d-flex flex-wrap" style="gap: 10px">
                <b-badge
                  variant="primary"
                  v-for="(item, index) in JSON.parse(data.item.keyword)"
                  :key="index"
                >
                  {{ item }}
                </b-badge>
              </div>
            </template>
            <template #cell(action)="data">
              <feather-icon
                class="clickable text-danger"
                icon="TrashIcon"
                size="18"
                @click="deleteKeyword(data.item.id)"
              />
            </template>
          </b-table>
        </div>
      </ValidationProvider>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      keyword: [],
      fields: [
        {
          key: "keyword",
          thStyle: {
            width: "60%",
          },
        },
        {
          key: "created_at",
          tdClass: "text-center",
          thClass: "text-center",
          formatter: (value) => {
            if (value) return moment(value).format("MM/DD/YYYY hh:mm A");
            return "-";
          },
        },
        {
          key: "action",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      data: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalAddKeywork");
  },
  created() {
    this.getKeywords();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getKeywords() {
      const { data } = await SettingsServiceSp.getKeywords({
        id: this.item.id,
      });
      this.data = data;
    },
    async saveKeywords() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      try {
        this.addPreloader();
        await SettingsServiceSp.saveKeywords({
          id: this.item.id,
          keyword: this.keyword,
          user_id: this.currentUser.user_id,
        });
        this.showToast(
          "success",
          "top-right",
          "Congratulation!",
          "CheckIcon",
          "Keywords has been added"
        );
        this.keyword = [];
        this.$emit("refresh");
      } catch (error) {
        this.showToast(
          "error",
          "top-right",
          "Oops!",
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.getKeywords();
        this.removePreloader();
      }
    },
    async deleteKeyword(id) {
      const confirm = await this.showConfirmSwal(
        "Are you sure to delete this keyword?", null
      );
      if (!confirm.isConfirmed) return;
      try {
        this.addPreloader();
        await SettingsServiceSp.deleteKeyword({
          id,
        });
        this.showToast(
          "success",
          "top-right",
          "Congratulation!",
          "CheckIcon",
          "Keywords has been deleted"
        );
        this.$emit("refresh");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.getKeywords();
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.container-keywords {
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
}
</style>
