<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xl"
      :title="classificationNcr.name"
      @hide="closeModal()"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title text-light">
          <strong>{{ classificationNcr.name }}</strong>
        </h5>
        <!-- Emulate built in modal header close button action -->
        <button
          :disabled="loading"
          aria-label="Close"
          class="close"
          @click="hiddenModal(close)"
        >
          &#215;
        </button>
      </template>
      <div class="d-flex flex-row justify-content-between">
        <div class="wf-45">
          <h5>{{ typeClassificationText.current }}</h5>
          <b-input-group>
            <b-form-input
              v-model="textDataCurrent"
              :placeholder="`Enter ${typeClassificationText.current}`"
            />
            <b-input-group-append>
              <b-button variant="info" @click.prevent="addReasonOrInstruction"
                >ADD</b-button
              >
            </b-input-group-append>
          </b-input-group>
          <b-card class="mt-2" body-class="p-0">
            <ul class="container-data pr-1" :class="isDark">
              <li
                @click="selectedReasons(item, 'current')"
                v-for="(item, index) in dataCurrentResults"
                :key="index"
                :class="addClassSelected(item, 'currents')"
              >
                {{ item.value | firstLetterCapitalize }}
                <b-badge
                  variant="light-success"
                  v-if="item.addition"
                  class="float-right"
                  >NEW</b-badge
                >
                <b-badge
                  variant="light-danger"
                  v-if="item.addition"
                  @click="removeItem(item)"
                  class="float-right delete"
                >
                  <feather-icon icon="TrashIcon" size="16" />
                </b-badge>
              </li>
            </ul>
          </b-card>
        </div>
        <div
          cols="2"
          class="d-flex flex-column align-items-center justify-content-center btn-actions wf-10"
        >
          <b-button
            @click.prevent="resetData"
            size="sm"
            :variant="!isAggregatesRemoved ? 'outline-danger' : 'danger'"
            id="tooltip-target-0"
          >
            <tabler-icon icon="RefreshIcon" size="16" />
          </b-button>
          <b-tooltip target="tooltip-target-0" triggers="hover" placement="top">
            <b>Reload</b>
          </b-tooltip>

          <b-button
            @click.prevent="toTheDataAll"
            size="sm"
            :variant="
              totalDataCurrentSelected > 0 ? 'primary' : 'outline-primary'
            "
            id="tooltip-target-1"
            triggers="hover"
            class="position-relative"
          >
            <feather-icon icon="ArrowRightIcon" size="16" />
            <b-badge
              v-if="isAggregatesRemoved"
              class="rounded-circle position-absolute"
              :variant="isDark ? 'light' : 'dark'"
              >{{ totalReferedAll }}
              <span class="sr-only">unread messages</span></b-badge
            >
          </b-button>
          <b-tooltip
            target="tooltip-target-1"
            triggers="hover"
            placement="left"
          >
            <b>Mov to all</b>
          </b-tooltip>

          <b-button
            @click.prevent="toTheDataCurrent"
            size="sm"
            :variant="totalDataAllSelected > 0 ? 'primary' : 'outline-primary'"
            id="tooltip-target-2"
            triggers="hover"
            class="position-relative"
          >
            <feather-icon icon="ArrowLeftIcon" size="16" />
            <b-badge
              v-if="isAggregatesRemoved"
              class="rounded-circle position-absolute"
              :variant="isDark ? 'light' : 'dark'"
              >{{ totalReferedCurrent }}
              <span class="sr-only">unread messages</span></b-badge
            >
          </b-button>
          <b-tooltip
            target="tooltip-target-2"
            triggers="hover"
            placement="right"
          >
            <b>Mov to current</b>
          </b-tooltip>

          <b-button
            :disabled="loading"
            @click.prevent="save"
            size="sm"
            :variant="isAggregatesRemoved ? 'success' : 'outline-success'"
            id="tooltip-target-3"
            triggers="hover"
            class="position-relative"
          >
            <feather-icon icon="SaveIcon" size="16" />
            <b-badge
              v-if="isAggregatesRemoved"
              class="rounded-circle position-absolute"
              :variant="isDark ? 'light' : 'dark'"
              >{{ totalAggregatesRemoved }}
              <span class="sr-only">unread messages</span></b-badge
            >
          </b-button>
          <b-tooltip
            target="tooltip-target-3"
            triggers="hover"
            placement="bottom"
          >
            <b>Save changes</b>
          </b-tooltip>
        </div>
        <div class="wf-45">
          <h5>{{ typeClassificationText.all }}</h5>
          <b-form-input
            v-model="textDataAll"
            :placeholder="`Enter ${typeClassificationText.current}`"
          ></b-form-input>
          <b-card class="mt-2" body-class="p-0">
            <ul class="container-data pr-1" :class="isDark">
              <template v-for="(item, index) in dataAllResults">
                <li
                  @click="selectedReasons(item, 'all')"
                  :key="index"
                  :class="addClassSelected(item, 'all')"
                >
                  {{ item.value | firstLetterCapitalize }}
                </li>
              </template>
            </ul>
          </b-card>
        </div>
      </div>
    </b-modal>

    <b-modal
      size="sm"
      v-model="showModalAdd"
      :title="`Add ${typeClassification}`"
      centered
      ok-title="Save"
    >
      <form id="form_reasons" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
          ></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer>
        <b-button type="submit" form="form_reasons" variant="success">
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
  <!-- agregar new reason o instructions -->
</template>
<script>
import "animate.css";
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
export default {
  name: "EditClassificationNcr",
  props: {
    classificationNcr: {
      type: Object,
      default: () => null,
    },
    typeClassification: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      showModalAdd: false,
      textDataCurrent: "",
      textDataAll: "",
      showModal: false,
      dataAllMemory: [],
      dataAll: [],
      dataCurrent: [],
      dataCurrentMemory: [],
      loading: false,
      nameState: null,
      name: "",
    };
  },
  computed: {
    dataCurrentResults() {
      return this.dataCurrent.filter((item) =>
        item.value.toLowerCase().includes(this.textDataCurrent.toLowerCase())
      );
    },
    dataAllResults() {
      return this.dataAll.filter((item) =>
        item.value.toLowerCase().includes(this.textDataAll.toLowerCase())
      );
    },
    totalDataCurrentSelected() {
      let dataFilter = this.dataCurrent.filter((item) => item.selected);
      return dataFilter.length;
    },
    totalDataAllSelected() {
      let dataFilter = this.dataAll.filter((item) => item.selected);
      return dataFilter.length;
    },
    isAggregatesRemoved() {
      let totalAddsCurrent = this.dataCurrent.filter(
        (item) => item.transfered || item.addition
      );
      let totalAddsAll = this.dataAll.filter((item) => item.transfered);
      return totalAddsCurrent.length > 0 || totalAddsAll.length > 0;
    },
    totalAggregatesRemoved() {
      let totalAddsCurrent = this.dataCurrent.filter(
        (item) => item.transfered || item.addition
      );
      let totalAddsAll = this.dataAll.filter((item) => item.transfered);

      return totalAddsCurrent.length + totalAddsAll.length;
    },
    typeClassificationText() {
      if (this.typeClassification == "Reasons") {
        return {
          current: "Current reasons",
          all: "All the reasons",
        };
      } else {
        return {
          current: "Current instructions",
          all: "All the instructions",
        };
      }
    },
    isDark() {
      return this.isDarkSkin ? "theme-dark" : "theme-light";
    },
    totalReferedCurrent() {
      return this.dataCurrent.filter((item) => item.transfered).length;
    },
    totalReferedAll() {
      return this.dataAll.filter((item) => item.transfered).length;
    },
  },
  filters: {
    firstLetterCapitalize(valor) {
      if (!valor) return;
      return valor.charAt(0).toUpperCase() + valor.slice(1).toLowerCase();
    },
  },
  watch: {
    classificationNcr: {
      handler(value) {
        if (value) {
          let myDataCurrent = [];

          if (this.typeClassification === "Reasons") {
            myDataCurrent = value.reasons.map((item) => ({
              ...item,
              selected: false,
              transfered: false,
              addition: false,
            }));
          } else {
            myDataCurrent = value.instructions.map((item) => ({
              ...item,
              selected: false,
              transfered: false,
              addition: false,
            }));
          }

          myDataCurrent.sort((a, b) => a.id - b.id);
          this.dataCurrentMemory = myDataCurrent;
          this.dataCurrent = myDataCurrent;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.showModal = true;
    await this.getReasonsInstructionsAll();
  },
  methods: {
    async handleSubmit() {
      try {
        if (this.name.trim() === "") throw new Error("Name is required");
        const result = await this.showQuestionSwal("Are you sures?");
        if (result.isConfirmed) {
          this.addPreloader();
          const { success, message, data } =
            await SettingsServiceSp.saveReasonsOrInstructions({
              name: this.name.trim(),
              type: this.typeClassification == "Reasons" ? 1 : 2,
            });
          this.removePreloader();
          if (!success) throw new Error(message);
          this.dataCurrent.push({
            ...data,
            selected: false,
            transfered: false,
            addition: true,
          });
          this.showModalAdd = false;
          this.name = "";
        }
      } catch (ex) {
        this.removePreloader();
        this.showToast("danger", "top-right", "Error", "XIcon", ex.message);
      }
    },
    closeModal(reload = false) {
      this.showModal = false;
      this.$emit("close", reload);
    },
    async hiddenModal(methodClose) {
      if (this.isAggregatesRemoved) {
        const result = await this.showConfirmSwal(
          "Are you sure you want to close?",
          "Do you have pending changes"
        );
        if (result.isConfirmed) {
          methodClose();
        }
      } else {
        methodClose();
      }
    },
    async getReasonsInstructionsAll() {
      try {
        let myDataArray = [];
        if (this.typeClassification === "Reasons") {
          const dataReasons = await amgApi.get("/lead/ncr/get-reasons");

          const dataArray = dataReasons.data.map((item) => ({
            ...item,
            selected: false,
            transfered: false,
          }));

          myDataArray = dataArray.filter(
            (item) => !this.dataCurrent.some((item1) => item1.id === item.id)
          );
        } else {
          const dataInstructions = await amgApi.get(
            "/lead/ncr/get-instructions"
          );

          const dataArray = dataInstructions.data.map((item) => ({
            ...item,
            selected: false,
            transfered: false,
          }));

          myDataArray = dataArray.filter(
            (item) => !this.dataCurrent.some((item2) => item2.id === item.id)
          );
        }

        /** ordenar de menor a mayor */
        myDataArray.sort((a, b) => a.id - b.id);
        this.dataAllMemory = myDataArray;
        this.dataAll = myDataArray;
      } catch (ex) {}
    },
    selectedReasons(item, type) {
      if (item.addition) return;
      item.selected = !item.selected;
      switch (type) {
        case "current": {
          this.dataAll = this.dataAll.map((item) => ({
            ...item,
            selected: false,
          }));
          break;
        }
        case "all": {
          this.dataCurrent = this.dataCurrent.map((item) => ({
            ...item,
            selected: false,
          }));
          break;
        }
      }
    },
    toTheDataCurrent() {
      if (this.totalDataAllSelected > 0) {
        /** obtener los items seleccionados */
        let dataSelected = this.dataAll.filter(
          (item) => item.selected === true
        );

        /** obtener todos items no seleccionados */
        this.dataAll = this.dataAll.filter((item) => item.selected === false);

        /** agregar los items seleccionados al array  dataCurrent*/
        this.dataCurrent.push(
          ...dataSelected.map((item) => ({
            ...item,
            selected: false,
            addition: false,
            transfered: !this.dataCurrentMemory.find(
              (item1) => item1.id === item.id
            ),
          }))
        );
        this.dataCurrent.sort((a, b) => a.id - b.id);
        this.dataAll.sort((a, b) => a.id - b.id);
      }
    },
    toTheDataAll() {
      if (this.totalDataCurrentSelected > 0) {
        /** obtener los items seleccionados */
        let dataSelected = this.dataCurrent.filter(
          (item) => item.selected === true
        );

        /** obtener todos los items no seleccionados */
        this.dataCurrent = this.dataCurrent.filter(
          (item) => item.selected === false
        );

        /** agregar los items seleccionados al array  dataAll*/
        this.dataAll.push(
          ...dataSelected.map((item) => ({
            ...item,
            addition: false,
            selected: false,
            transfered: !this.dataAllMemory.find(
              (item1) => item1.id === item.id
            ),
          }))
        );

        this.dataCurrent.sort((a, b) => a.id - b.id);
        this.dataAll.sort((a, b) => a.id - b.id);
      }
    },
    addClassSelected(item, type) {
      let nameClass = "";
      if (type === "currents") {
        // item.selected ? 'selected' : ''
        if (item.transfered && item.selected) {
          nameClass = "selected transfered";
        }

        if (item.transfered) {
          nameClass = "transfered animate__animated animate__fadeIn";
        }

        if (item.addition) {
          nameClass = "new-addition";
        }
      } else {
        // item.selected ? 'selected' : ''
        if (item.transfered && item.selected) {
          nameClass = "selected transfered-all";
        }
        if (item.transfered) {
          nameClass = "transfered-all animate__animated animate__fadeIn";
        }
      }

      if (item.selected) {
        nameClass = "selected";
      }
      return nameClass;
    },
    resetData() {
      if (!this.isAggregatesRemoved) return;
      this.dataAll = this.dataAllMemory.map((item) => ({
        ...item,
        selected: false,
        transfered: false,
        addition: false,
      }));
      this.dataCurrent = this.dataCurrentMemory.map((item) => ({
        ...item,
        selected: false,
        transfered: false,
        addition: false,
      }));
    },
    async save() {
      if (!this.isAggregatesRemoved) return;
      try {
        const result = await this.showQuestionSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (result.isConfirmed) {
          this.addPreloader();
          this.loading = true;
          let newDataCurrent = this.dataCurrent.filter(
            (item) => item.transfered === true || item.addition
          );
          newDataCurrent = newDataCurrent.map((item) => ({ ...item, add: 1 }));

          let newDataAll = this.dataAll.filter(
            (item) => item.transfered === true
          );
          newDataAll = newDataAll.map((item) => ({ ...item, add: 0 }));

          const detailsClassifications = newDataCurrent.concat(newDataAll);

          const formData = {
            detailsClassifications,
            typeClassificationNcr: this.typeClassification == "Reasons" ? 1 : 2,
            classification_ncr_id: this.classificationNcr.id,
          };

          await SettingsServiceSp.saveClassificationReasonInstructionDetails(
            formData
          );
          this.removePreloader();
          this.loading = false;
          this.closeModal(true);
           this.$emit("refresh");
        }
      } catch (ex) {
        this.removePreloader();
        this.loading = false;
      }
    },
    addReasonOrInstruction() {
      this.name = this.textDataCurrent;
      this.showModalAdd = true;
    },
    async removeItem(data) {
      try {
        const result = await this.showQuestionSwal(
          "Are you sure you want to remove it?",
          "You won't be able to revert this!"
        );
        if (result.isConfirmed) {
          let keyExists = this.dataCurrent.findIndex(
            (item) => item.id === data.id
          );
          if (keyExists > -1) {
            this.addPreloader();

            await SettingsServiceSp.deleteReasonsOrInstructions({
              type: this.typeClassification == "Reasons" ? 1 : 2,
              id: data.id,
            });
            this.removePreloader();

            this.dataCurrent.splice(keyExists, 1);
          }
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.container-data {
  height: 600px;
  overflow: auto;
  list-style: none;
  padding: 0;
}
ul.container-data li {
  padding: 3px 5px;
  margin: 0 0 12px 0;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: background-color 0.2s ease-in-out;
}
ul.container-data.theme-light li {
  background: #fafafa;
  box-shadow: 1px 1px 2px rgba(38, 50, 56, 0.1);
}

ul.container-data.theme-dark li {
  background: #212121;
  box-shadow: 1px 1px 2px rgba(207, 216, 220, 0.1);
}

.btn-actions button {
  margin-block-end: 5px;
}

ul.container-data li.selected {
  background: #00695dcb;
  color: #fafafa;
  transition: background-color 0.3s ease-in-out;
  border: 1px solid transparent;
}
ul.container-data.theme-dark li.selected {
  box-shadow: 1px 1px 2px #004d4094;
}
ul.container-data.theme-light li.selected {
  box-shadow: 1px 1px 2px rgba(38, 50, 56, 0.192);
}
ul.container-data li.transfered {
  border: 1px solid #00695d69;
  box-shadow: 1px 1px 2px #004d4071;
}
ul.container-data li.new-addition {
  border: 1px solid #ff7043;
  box-shadow: 1px 1px 2px #ff562242;
  position: relative;
  cursor: default;
}
span.badge-light {
  color: #000 !important;
}
ul.container-data li.transfered-all {
  border: 1px solid #fa555569;
  box-shadow: 1px 1px 2px #fa55554f;
}
.wf-45 {
  width: 45%;
}
.wf-10 {
  width: 10%;
}
li.new-addition span.delete {
  cursor: pointer;
}
li.new-addition span {
  margin-inline-start: 5px;
}
</style>
